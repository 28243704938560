import React from 'react';
import { createRoot } from 'react-dom/client';
import { MMProvider, PrivateLayout } from '@machinemetrics/mm-react-tools';
import { Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import './index.css';
import App from './App';
import AppProvider from './context/AppProvider';
import config from './config/default.json';
import theme from './theme/customTheme';

const root = createRoot(document.getElementById('root'));

root.render(
  <MMProvider
    releaseStage={config.releaseStage}
    domain={config.domain}
    urls={config.urls}
    clientId={config.clientId}
    clientSecret={config.clientSecret}
    scope={'reporting user'}
  >
    <Route element={<PrivateLayout />}>
      <Route
        path="/"
        element={
          <AppProvider>
            <ChakraProvider theme={theme}>
              <App />
            </ChakraProvider>
          </AppProvider>
        }
      />
    </Route>
  </MMProvider>
);
