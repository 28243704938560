import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { erpResourcesQuery, machineQuery } from '../graphql/queries';

const useResourceMappings = () => {
  const [resourceMappings, setResourceMappings] = useState({});
  const { data: erpResources } = useQuery(erpResourcesQuery);
  const { data: machines } = useQuery(machineQuery);

  useEffect(() => {
    if (erpResources && machines) {
      const initialResourceMappings = erpResources.erpResources.reduce(
        (acc, resource) => {
          acc[resource.resourceId] = {
            machineRef: resource.machineRef,
            machineGroupId: resource.machineGroupId,
          };
          return acc;
        },
        {}
      );

      setResourceMappings(initialResourceMappings);
    }
  }, [erpResources, machines, setResourceMappings]);

  return [resourceMappings, setResourceMappings];
};

export default useResourceMappings;
