import React from 'react';
import { Heading } from '@chakra-ui/react';
import {
  StyledImportSummary,
  StyledImportSummaryItem,
} from './styledComponents';

const Settings = ({
  resourceMappings,
  newResourceMappings,
  newOperatorMappings,
  newReasonMappings,
  machines,
  machineIntegrationStatuses,
  setMachineIntegrationStatuses,
}) => {
  return (
    <div>
      {/* <SettingsComponent
        resourceMappings={resourceMappings}
        machines={machines}
        machineIntegrationStatuses={machineIntegrationStatuses}
        setMachineIntegrationStatuses={setMachineIntegrationStatuses}
      /> */}
      <Heading size="lg" mb={5}>
        Update Summary
      </Heading>
      <StyledImportSummary>
        <StyledImportSummaryItem>
          {Object.keys(newResourceMappings).length} Machines Updated
        </StyledImportSummaryItem>
        <StyledImportSummaryItem>
          {Object.keys(newOperatorMappings).length} Persons Updated
        </StyledImportSummaryItem>
        <StyledImportSummaryItem>
          {' '}
          {Object.keys(newReasonMappings).length} Reasons Updated
        </StyledImportSummaryItem>
      </StyledImportSummary>
    </div>
  );
};

export default Settings;
