import React, { useState, useEffect } from 'react';
import { useMMAuth } from '@machinemetrics/mm-react-tools';
import { useQuery, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Heading,
  TabPanel,
  TabPanels,
  useToast,
} from '@chakra-ui/react';
import {
  StyledBox,
  StyledTabs,
  StyledFooter,
  StyledButtonContainer,
  StyledContentBox,
  CustomStepper,
  Resources,
  People,
  Reasons,
  Settings,
} from './components';
import {
  erpReasonsQuery,
  erpResourcesQuery,
  machineQuery,
} from './graphql/queries';
import {
  updateResourceMutation,
  updateAnnotationMutation,
  updateRejectReasonMutation,
} from './graphql/mutations';
import {
  handleFinishOperatorUpdates,
  handleFinishReasonUpdates,
} from './utils/helperFuncs';
import {
  useFetchMachineGroups,
  useFetchOperators,
  useResourceMappings,
} from './hooks';

const steps = [
  {
    title: 'Resources',
    instructions:
      'Map the Resources from your ERP to their equivalent Machines and Machine Groups in MachineMetrics.',
  },
  {
    title: 'People',
    instructions: 'Map the People from your ERP to Operators in MachineMetrics',
  },
  {
    title: 'Reasons',
    instructions:
      'Map the Reasons from your ERP to Downtime and Reject Reasons in MachineMetrics.',
  },
  {
    title: 'Review & Finish',
    instructions:
      'Review your mappings and finish the setup process to save changes',
  },
];

const App = () => {
  const toast = useToast();

  const [tabIndex, setTabIndex] = useState(0);
  const { request, urls } = useMMAuth();
  const { operators, selectedOperators, setSelectedOperators } =
    useFetchOperators();
  const machineGroups = useFetchMachineGroups();
  const [resourceMappings, setResourceMappings] = useResourceMappings();
  const [reasonMappings, setReasonMappings] = useState({});
  const [newReasonMappings, setNewReasonMappings] = useState({});
  const [newResourceMappings, setNewResourceMappings] = useState({});
  const [newOperatorMappings, setNewOperatorMappings] = useState({});
  const [machineIntegrationStatuses, setMachineIntegrationStatuses] = useState(
    {}
  );
  const { data: erpReasons } = useQuery(erpReasonsQuery);
  const { data: erpResources } = useQuery(erpResourcesQuery);
  const { data: machines } = useQuery(machineQuery);
  const [updateAnnotationType] = useMutation(updateAnnotationMutation);
  const [updateRejectReason] = useMutation(updateRejectReasonMutation);
  const [updateResource] = useMutation(updateResourceMutation);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (erpResources && machines) {
      const initialResourceMappings = erpResources.erpResources.reduce(
        (acc, resource) => {
          acc[resource.resourceId] = {
            machineRef: resource.machineRef,
            machineGroupId: resource.machineGroupId,
          };
          return acc;
        },
        {}
      );

      setResourceMappings(initialResourceMappings);
    }
  }, [erpResources, machines]);

  useEffect(() => {
    if (erpReasons) {
      const fetchedData = erpReasons.erpReasons;
      const initialMappings = fetchedData.reduce((acc, reason) => {
        acc[reason.reasonRef] = {
          annotationType: reason.annotationTypeRef,
          rejectReason: reason.rejectReasonRef,
        };
        return acc;
      }, {});

      setReasonMappings(initialMappings);
    }
  }, [erpReasons]);

  const handleReasonMappingChange = (reasonRef, type, selection) => {
    setReasonMappings((prev) => ({
      ...prev,
      [reasonRef]: { ...prev[reasonRef], [type]: selection },
    }));
    setNewReasonMappings((prev) => ({
      ...prev,
      [reasonRef]: { ...prev[reasonRef], [type]: selection },
    }));
  };

  const handleResourceMappingChange = (resourceId, newMapping) => {
    setNewResourceMappings((prev) => ({
      ...prev,
      [resourceId]: newMapping,
    }));
    setResourceMappings((prev) => ({
      ...prev,
      [resourceId]: newMapping,
    }));
  };

  const handleOperatorChange = (personId, newOperatorId) => {
    setSelectedOperators((prev) => ({
      ...prev,
      [personId]: newOperatorId,
    }));
    setNewOperatorMappings((prev) => ({
      ...prev,
      [personId]: newOperatorId,
    }));
  };

  const handleTabsChange = (index) => setTabIndex(index);

  const handleNext = () => {
    if (tabIndex < steps.length - 1) setTabIndex(tabIndex + 1);
  };

  const handleBack = () => {
    if (tabIndex > 0) setTabIndex(tabIndex - 1);
  };

  const handleFinish = async () => {
    setIsSaving(true);
    const mutationPromises = Object.entries(newResourceMappings).map(
      ([resourceId, { machineRef, machineGroupId }]) =>
        updateResource({
          variables: {
            resourceId,
            machineRef: machineRef === null ? null : parseInt(machineRef),
            machineGroupId: machineGroupId === null ? null : machineGroupId,
          },
        })
    );

    try {
      await Promise.all(mutationPromises);
      await handleFinishOperatorUpdates(
        selectedOperators,
        operators,
        request,
        urls
      );
      await handleFinishReasonUpdates(
        reasonMappings,
        updateAnnotationType,
        updateRejectReason
      );

      setIsSaving(false);

      toast({
        title: 'Update Completed',
        description: 'All mappings have been saved successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setNewOperatorMappings({});
      setNewResourceMappings({});
      setNewReasonMappings({});
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An error occurred while saving the mappings.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsSaving(false);
    }
  };

  return (
    <Box width="100%" bg="bgGrey" height="100%">
      <Box bg="bgGrey">
        <Heading as="h3" size="lg" m={4}>
          ERP Resource Mapper
        </Heading>
      </Box>
      <StyledBox>
        <CustomStepper tabIndex={tabIndex} steps={steps} />
        <StyledTabs index={tabIndex} onChange={handleTabsChange}>
          <TabPanels>
            <TabPanel padding={'0px'}>
              <StyledContentBox>
                {erpResources && machines && machineGroups && (
                  <Resources
                    machineGroups={machineGroups}
                    machines={machines}
                    erpResources={erpResources}
                    resourceMappings={resourceMappings}
                    handleResourceMappingChange={handleResourceMappingChange}
                  />
                )}
              </StyledContentBox>
            </TabPanel>
            <TabPanel padding={'0px'}>
              <StyledContentBox>
                <People
                  selectedOperators={selectedOperators}
                  onOperatorChange={handleOperatorChange}
                  operators={operators}
                />
              </StyledContentBox>
            </TabPanel>
            <TabPanel padding={'0px'}>
              <StyledContentBox>
                {erpReasons && (
                  <Reasons
                    reasonMappings={reasonMappings}
                    onReasonSelectionChange={handleReasonMappingChange}
                    erpReasons={erpReasons}
                  />
                )}
              </StyledContentBox>
            </TabPanel>
            <TabPanel padding={'0px'}>
              <StyledContentBox>
                <Settings
                  reasonMappings={reasonMappings}
                  newResourceMappings={newResourceMappings}
                  newOperatorMappings={newOperatorMappings}
                  newReasonMappings={newReasonMappings}
                  selectedOperators={selectedOperators}
                  machines={machines}
                  setMachineIntegrationStatuses={setMachineIntegrationStatuses}
                  machineIntegrationStatuses={machineIntegrationStatuses}
                />
              </StyledContentBox>
            </TabPanel>
          </TabPanels>
        </StyledTabs>
        <StyledFooter bg="bgGrey">
          <StyledButtonContainer>
            {tabIndex > 0 && (
              <Button m={2} bg="white" variant="outline" onClick={handleBack}>
                Previous
              </Button>
            )}
            {tabIndex < steps.length - 1 && (
              <Button m={2} colorScheme="customIndigo" onClick={handleNext}>
                Next
              </Button>
            )}
            {tabIndex === steps.length - 1 && (
              <Button
                m={2}
                colorScheme="customIndigo"
                onClick={handleFinish}
                isDisabled={
                  (Object.keys(newReasonMappings).length === 0 &&
                    Object.keys(newResourceMappings).length === 0 &&
                    Object.keys(newOperatorMappings).length === 0) ||
                  isSaving
                }
              >
                Save & Finish
              </Button>
            )}
          </StyledButtonContainer>
        </StyledFooter>
      </StyledBox>
    </Box>
  );
};

export default App;
