import { useState, useEffect } from 'react';
import { useMMAuth } from '@machinemetrics/mm-react-tools';

const useFetchMachineGroups = () => {
  const { request, urls } = useMMAuth();
  const [machineGroups, setMachineGroups] = useState([]);

  useEffect(() => {
    const fetchMachineGroups = async () => {
      const response = await request(`${urls.apiUrl}/machine-groups`, {
        method: 'GET',
      });

      if (!response.error && Array.isArray(response))
        setMachineGroups(response);
    };

    fetchMachineGroups();
  }, [request, urls.apiUrl]);

  return machineGroups;
};

export default useFetchMachineGroups;
