import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle({
  tablist: {
    color: 'Palette.Grey600',
    display: 'flex',
    gap: '1.5rem',
    alignItems: 'flex-end',
    minWidth: 'fit-content',
    paddingLeft: '1.25rem',
    paddingRight: '1.25rem',
  },
  tabPanel: {
    padding: '1.5rem',
  },
  tab: {
    cursor: 'pointer',
  },
});

export const tabsTheme = defineMultiStyleConfig({ baseStyle });
