import { gql } from '@apollo/client';

export const updateResourceMutation = gql`
  mutation UpdateResource(
    $resourceId: String!
    $machineRef: Int
    $machineGroupId: String
  ) {
    updateErpResources(
      where: { resourceId: { _eq: $resourceId } }
      _set: { machineRef: $machineRef, machineGroupId: $machineGroupId }
    ) {
      affected_rows
      returning {
        resourceId
        machineGroupId
        machineRef
      }
    }
  }
`;

export const updateAnnotationMutation = gql`
  mutation UpdateAnnotationType($reasonRef: bigint!, $annotationTypeRef: Int!) {
    updateErpReasons(
      where: { reasonRef: { _eq: $reasonRef } }
      _set: { annotationTypeRef: $annotationTypeRef }
    ) {
      affected_rows
      returning {
        reasonRef
        annotationType {
          name
        }
      }
    }
  }
`;

export const updateRejectReasonMutation = gql`
  mutation UpdateRejectReason($reasonRef: bigint!, $rejectReasonRef: Int!) {
    updateErpReasons(
      where: { reasonRef: { _eq: $reasonRef } }
      _set: { rejectReasonRef: $rejectReasonRef }
    ) {
      affected_rows
      returning {
        reasonRef
        rejectReason {
          name
        }
      }
    }
  }
`;
