import React, { useMemo, useState } from 'react';
import { Box, Input, Table, Thead, Tbody, Tr, Th } from '@chakra-ui/react';
import ResourceItem from './ResourceItem';

function Resources({
  machineGroups,
  machines,
  resourceMappings,
  handleResourceMappingChange,
  erpResources,
}) {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredData = useMemo(() => {
    return erpResources.erpResources.filter(
      (resource) =>
        (resource.resourceId ? resource.resourceId.toLowerCase() : '').includes(
          searchTerm.toLowerCase()
        ) ||
        (resource.name ? resource.name.toLowerCase() : '').includes(
          searchTerm.toLowerCase()
        )
    );
  }, [erpResources.erpResources, searchTerm]);

  return (
    <Box width="100%">
      <Input
        placeholder="Filter by ResourceId or Name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        mb={4}
      />
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ERP Resource</Th>
            <Th>Description</Th>
            <Th>Machine</Th>
            <Th>Machine Group</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredData.map((resource) => (
            <ResourceItem
              key={resource.resourceId}
              resource={resource}
              machines={machines.machines}
              machineGroups={machineGroups}
              currentMapping={resourceMappings[resource.resourceId]}
              handleResourceMappingChange={handleResourceMappingChange}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}

export default Resources;
