import React, { useState, useEffect, useMemo } from 'react';
import ReasonItem from './ReasonItem';
import { Box, Input, Table, Thead, Tbody, Tr, Th } from '@chakra-ui/react';
import { useMMAuth } from '@machinemetrics/mm-react-tools';

const Reasons = ({ reasonMappings, onReasonSelectionChange, erpReasons }) => {
  const { request, urls } = useMMAuth();
  const [searchTerm, setSearchTerm] = useState('');

  const [annotations, setAnnotations] = useState([]);
  const [partAdjustmentTypes, setPartAdjustmentTypes] = useState([]);

  useEffect(() => {
    const fetchAnnotations = async () => {
      const response = await request(`${urls.apiUrl}/annotation-types`, {
        method: 'GET',
      });

      if (!response.error && Array.isArray(response)) setAnnotations(response);
    };

    fetchAnnotations();
  }, [request, urls.apiUrl]);

  // Fetch Part Adjustment Types
  useEffect(() => {
    const fetchPartAdjustmentTypes = async () => {
      const response = await request(`${urls.apiUrl}/part-adjustment-types`, {
        method: 'GET',
      });

      if (!response.error && Array.isArray(response))
        setPartAdjustmentTypes(response);
    };

    fetchPartAdjustmentTypes();
  }, [request, urls.apiUrl]);

  const filteredReasons = useMemo(() => {
    return erpReasons.erpReasons.filter((reason) =>
      (reason.code ? reason.code.toLowerCase() : '').includes(
        searchTerm.toLowerCase()
      )
    );
  }, [erpReasons.erpReasons, searchTerm]);
  return (
    <Box width="100%">
      <Input
        placeholder="Filter by Code"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        mb={4}
      />
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ERP Reason Code</Th>
            <Th>Description</Th>
            <Th>Downtime Reason</Th>
            <Th>Reject Reason</Th>
          </Tr>
        </Thead>
        <Tbody>
          {erpReasons &&
            filteredReasons.map((reason) => (
              <ReasonItem
                key={reason.reasonId}
                reason={reason}
                annotations={annotations}
                partAdjustmentTypes={partAdjustmentTypes}
                onSelectionChange={onReasonSelectionChange}
                reasonMappings={reasonMappings}
              />
            ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default Reasons;
