import Palette from './palette';

export const getToastColor = (status) => {
  switch (status) {
    case 'success':
      return Palette.Grey800;
    case 'error':
      return Palette.Red600;
    default:
      return Palette.Grey800;
  }
};
