import React from 'react';
import { Box, Flex, Stack } from '@chakra-ui/react';
import {
  Step,
  StepDescription,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  StepIcon,
} from '@chakra-ui/react';
import { StyledInfoBox, StyledText } from './styledComponents';

const CustomStepper = ({ tabIndex, steps }) => {
  return (
    <React.Fragment>
      <Stack>
        <Stepper size="sm" colorScheme="customIndigo" index={tabIndex}>
          {steps.map((step, index) => (
            <Step key={index} gap="0">
              <Flex flexDirection="column" alignItems="flex-start">
                <Box minWidth="100%">
                  <Flex flexDirection="column" alignItems="center">
                    <StepIndicator>
                      <StepStatus
                        active={<StepNumber />}
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                      />
                    </StepIndicator>
                    <StepTitle textAlign="center" width="150px">
                      {step.title}
                    </StepTitle>
                  </Flex>
                </Box>
              </Flex>
              <Box flexShrink="0">
                <StepDescription>{step.description}</StepDescription>
              </Box>
              <StepSeparator minWidth="5vw" />
            </Step>
          ))}
        </Stepper>
      </Stack>
      <StyledInfoBox>
        <StyledText>{steps[tabIndex].instructions}</StyledText>
      </StyledInfoBox>
    </React.Fragment>
  );
};
export default CustomStepper;
