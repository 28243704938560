export {
  StyledBox,
  StyledTabs,
  StyledFooter,
  StyledButtonContainer,
  StyledContentBox,
} from './styledComponents';
export { default as CustomStepper } from './CustomStepper';
export { default as Resources } from './Resources';
export { default as People } from './People';
export { default as Reasons } from './Reasons';
export { default as Settings } from './Settings';
