export const handleFinishOperatorUpdates = async (
  selectedOperators,
  operators,
  request,
  urls
) => {
  const changes = [];

  // Identify changes to operator mapping
  for (const [erpId, operatorId] of Object.entries(selectedOperators)) {
    const currentOperator = operators.find((op) => op.erpId === erpId);

    if (operatorId && (!currentOperator || currentOperator.id !== operatorId)) {
      changes.push({ operatorId, erpId });
    } else if (!operatorId && currentOperator) {
      changes.push({ operatorId: currentOperator.id, erpId: null });
    }
  }

  operators.forEach((op) => {
    if (op.erpId && !Object.values(selectedOperators).includes(op.id)) {
      changes.push({ operatorId: op.id, erpId: null });
    }
  });

  await clearAllOperatorAssignments(changes, request, urls);

  const updatePromises = changes.map((change) =>
    request(`${urls.apiUrl}/operator/${change.operatorId}`, {
      method: 'PUT',
      body: JSON.stringify({ erpId: change.erpId }), // Set erpId to null for unmapping
    }).catch((error) =>
      console.error(`Error updating operator ${change.operatorId}:`, error)
    )
  );

  try {
    const responses = await Promise.all(updatePromises);

    responses.forEach((response, index) => {
      if (response.error) {
        console.error(
          `Error updating operator ${changes[index].operatorId}:`,
          response.error
        );
      }
    });

    // If any response contains an error, throw a new error
    if (responses.some((response) => response.error)) {
      throw new Error('Error updating operator');
    }
  } catch (error) {
    console.error('Error applying operator updates:', error);
    throw error; // Rethrow the error
  }
};

export const clearAllOperatorAssignments = async (operators, request, urls) => {
  const updatePromises = [];

  for (const operator of operators) {
    const operatorId = operator.operatorId;
    if (operatorId) {
      const promise = request(`${urls.apiUrl}/operator/${operatorId}`, {
        method: 'PUT',
        body: JSON.stringify({ erpId: null }),
      }).catch((error) => {
        console.error(
          `Error clearing ERP ID for operator ${operatorId}:`,
          error
        );
      });

      updatePromises.push(promise);
    }
  }
  try {
    await Promise.all(updatePromises);
  } catch (error) {
    console.error('Error in clearing operator assignments:', error);
    // Handle errors - I need to but in a toast
  }
};

export const handleFinishReasonUpdates = async (
  reasonSelections,
  updateAnnotationType,
  updateRejectReason
) => {
  const updatePromises = Object.entries(reasonSelections).flatMap(
    ([reasonRef, { annotationType, rejectReason }]) => [
      updateAnnotationType({
        variables: {
          reasonRef: parseInt(reasonRef),
          annotationTypeRef:
            annotationType === null ? null : parseInt(annotationType),
        },
      }),
      updateRejectReason({
        variables: {
          reasonRef: parseInt(reasonRef),
          rejectReasonRef:
            rejectReason === null ? null : parseInt(rejectReason),
        },
      }),
    ]
  );

  try {
    const responses = await Promise.all(updatePromises);

    if (responses.some((response) => response.error)) {
      throw new Error('Error updating reasons');
    }
    console.log('All reason updates applied successfully.');
  } catch (error) {
    console.error('Error applying reason updates:', error);
    throw error;
  }
};
