import gql from 'graphql-tag';

export const erpResourcesQuery = gql`
  query erpResourcesQuery {
    erpResources(order_by: { resourceId: asc }) {
      resourceId
      name
      description
      isResourceGroup
      machineRef
      machineGroupId
    }
  }
`;

export const machineQuery = gql`
  query MachinesQuery {
    machines(
      where: { decommissionedAt: { _is_null: true } }
      order_by: { name: asc }
    ) {
      name
      machineRef
      machineId
    }
  }
`;

export const erpReasonsQuery = gql`
  query erpReasonsQuery {
    erpReasons(order_by: { reasonId: asc }) {
      code
      category
      reasonRef
      reasonId
      entityType
      description
      rejectReasonRef
      annotationTypeRef
    }
  }
`;

export const erpPartsQuery = gql`
  query erpPartsQuery($limit: Int = 50, $offset: Int = 0) {
    erpParts(order_by: { partNumber: asc }, limit: $limit, offset: $offset) {
      partNumber
      method
      partRevision
    }
  }
`;

export const erpPartOperationsQuery = gql`
  query erpPartOperationsQuery($limit: Int = 50, $offset: Int = 0) {
    erpPartOperations(
      order_by: { partNumber: asc }
      limit: $limit
      offset: $offset
    ) {
      partNumber
      sequenceNumber
      partRevision
      cycleTimeMs
      description
      method
      setupTimeMs
    }
  }
`;

export const erpWorkOrdersQuery = gql`
  query erpWorkOrdersQuery($limit: Int = 50, $offset: Int = 0) {
    erpWorkOrders(
      order_by: { workOrderId: asc }
      limit: $limit
      offset: $offset
    ) {
      workOrderId
      lot
      sub
      split
      status
      partNumber
      partRevision
      scheduledStartDate
      scheduledEndDate
      quantityRequired
      closedDate
      description
      dueDate
    }
  }
`;

export const erpWorkOrderOperationsQuery = gql`
  query erpWorkOrderOperationsQuery($limit: Int = 50, $offset: Int = 0) {
    erpWorkOrderOperations(
      order_by: { workOrderId: asc }
      limit: $limit
      offset: $offset
    ) {
      workOrderId
      lot
      sub
      split
      sequenceNumber
      status
      startQuantity
      finishQuantity
      scheduledFinishDate
      scheduledStartDate
      resourceId
      cycleTimeMs
      setupTimeMs
      operationType
    }
  }
`;
