import React from 'react';
import { StyledSelect } from './styledComponents';
import { Tr, Td, Text } from '@chakra-ui/react';

function ReasonItem({
  reason,
  annotations,
  partAdjustmentTypes,
  onSelectionChange,
  reasonMappings,
}) {
  const handleAnnotationChange = (event) => {
    const newAnnotationTypeId = event.target.value;
    onSelectionChange(reason.reasonRef, 'annotationType', newAnnotationTypeId);
  };

  const handleRejectReasonChange = (event) => {
    const newRejectReasonRef = event.target.value;
    onSelectionChange(reason.reasonRef, 'rejectReason', newRejectReasonRef);
  };

  const selectedAnnotation =
    reasonMappings[reason.reasonRef]?.annotationType || '';
  const selectedRejectReason =
    reasonMappings[reason.reasonRef]?.rejectReason || '';

  return (
    <Tr>
      <Td>
        <Text fontSize="md">{reason.code || reason.reasonId}</Text>
      </Td>
      <Td>{reason.description}</Td>
      <Td>
        <StyledSelect
          placeholder="Select Downtime Reason"
          value={selectedAnnotation}
          onChange={handleAnnotationChange}
          isDisabled={selectedRejectReason !== ''}
        >
          {annotations.map((annotation) => (
            <option key={annotation.id} value={annotation.id}>
              {annotation.name}
            </option>
          ))}
        </StyledSelect>
      </Td>
      <Td>
        <StyledSelect
          placeholder="Select Reject Reason"
          value={selectedRejectReason}
          onChange={handleRejectReasonChange}
          isDisabled={selectedAnnotation !== ''}
        >
          {partAdjustmentTypes.map((type) => (
            <option key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </StyledSelect>
      </Td>
    </Tr>
  );
}

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.reason.reasonRef === nextProps.reason.reasonRef &&
    prevProps.reasonMappings[prevProps.reason.reasonRef]?.annotationType ===
      nextProps.reasonMappings[nextProps.reason.reasonRef]?.annotationType &&
    prevProps.reasonMappings[prevProps.reason.reasonRef]?.rejectReason ===
      nextProps.reasonMappings[nextProps.reason.reasonRef]?.rejectReason &&
    prevProps.annotations === nextProps.annotations &&
    prevProps.partAdjustmentTypes === nextProps.partAdjustmentTypes
  );
};

export default React.memo(ReasonItem, areEqual);
