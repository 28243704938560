import { extendTheme } from '@chakra-ui/react';
import { zLayer } from './styled';
import { tabsTheme } from './tabsTheme';
import Palette from './palette';
import { getToastColor } from './utils';
import { tagTheme } from './tagTheme';

const zIndices = {
  // updated the ones causing issues but may discover the need to override others as we use Chakra
  hide: -1,
  auto: 'auto',
  base: 0,
  docked: zLayer.raised,
  dropdown: zLayer.menu,
  sticky: 1100,
  banner: 1200,
  overlay: zLayer.screen,
  modal: zLayer.modal,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800,
};
// default values to override:
// zIndices: {
//   hide: -1,
//   auto: 'auto',
//   base: 0,
//   docked: 10,
//   dropdown: 1000,
//   sticky: 1100,
//   banner: 1200,
//   overlay: 1300,
//   modal: 1400,
//   popover: 1500,
//   skipLink: 1600,
//   toast: 1700,
//   tooltip: 1800,
// }

const theme = extendTheme({
  zIndices,
  styles: {
    global: () => ({
      body: {
        bg: '',
        // TODO: this should ultimately be tied into our theme but we do not have access to our theme here yet. A hook that lets us access our theme is forthcoming. (Ben 3/28/23)
        color: 'Palette.Grey700',
      },
    }),
  },
  colors: {
    Palette: {
      ...Palette,
    },
    bgGrey: '#f7fafc',
    bgWhite: '#ffffff',
    customIndigo: {
      500: '#5A67D8', // The primary color
    },
    indigo: {
      50: Palette.Indigo50,
      100: Palette.Indigo100,
      200: Palette.Indigo200,
      300: Palette.Indigo300,
      400: Palette.Indigo400,
      500: Palette.Indigo500,
      600: Palette.Indigo600,
      700: Palette.Indigo700,
      800: Palette.Indigo800,
      900: Palette.Indigo900,
    },
  },
  fonts: {
    heading: `'Noto Sans', Arial, sans-serif`,
    body: `'Noto Sans', Arial, sans-serif`,
  },
  components: {
    Button: {
      baseStyle: {
        color: 'white',
        _hover: {
          bg: 'customIndigo.500',
        },
      },
    },
    Radio: {
      baseStyle: {
        _focus: {
          boxShadow: '0 0 0 2px #5A67D8', // This will change the outline color
        },
      },
    },
    Tabs: {
      variants: {
        line: {
          tab: {
            flexGrow: 0,
            _selected: {
              borderColor: Palette.Indigo600,
              color: Palette.Indigo600,
            },

            _active: { '--tabs-bg': Palette.Grey100 },
          },
        },
      },
    },
    Tab: {
      baseStyle: {
        _selected: {
          color: 'Palette.Grey700',
        },
        _focus: {
          boxShadow: '0 0 0 2px #5A67D8', // This will change the outline color
        },
      },
    },
    Tooltip: {
      baseStyle: {
        fontSize: 'xs',
      },
    },
    Alert: {
      baseStyle: (props) => {
        const { status, id } = props;

        // Toast components
        if (id?.includes('toast')) {
          return {
            container: {
              background: getToastColor(status),
            },
          };
        }

        // Alert components
        return undefined;
      },
    },
    Modal: {
      baseStyle: {
        header: {
          color: Palette.Grey700,
          fontWeight: 'normal',
        },
        closeButton: {
          _hover: { cursor: 'pointer', bg: Palette.Grey200 },
          top: 'var(--chakra-space-4)',
        },
        body: {
          paddingTop: '1rem',
          paddingBottom: '1rem',
          borderTop: `1px solid ${Palette.Grey300}`,
        },
        footer: {
          borderTop: `1px solid ${Palette.Grey300}`,
        },
      },
      sizes: {
        '2xl': { dialog: { maxW: '40rem' } },
        '3xl': { dialog: { maxW: '45rem' } },
        '4xl': { dialog: { maxW: '50rem' } },
        '5xl': { dialog: { maxW: '55rem' } },
        '6xl': { dialog: { maxW: '60rem' } },
        '7xl': { dialog: { maxW: '65rem' } },
      },
    },
    Menu: {
      baseStyle: {
        item: {
          cursor: 'pointer',
          fontSize: '0.875rem',
        },
        list: {
          zIndex: zLayer.menu,
        },
      },
    },
    Tag: {
      ...tagTheme,
      sizes: {
        sm: {
          container: { fontSize: '0.625rem' },
        },
        md: {
          container: { fontSize: '0.75rem' },
        },
        lg: {
          container: { fontSize: '0.875rem' },
        },
      },
    },
    Box: {
      baseStyle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '24px',
        gap: '32px',
        borderRadius: '4px',
        border: '1px solid',
        borderColor: Palette.Grey400,
        background: Palette.Grey300,
      },
    },
    Stepper: {
      // Styles for StyledStepper
      baseStyle: {
        display: 'flex',
        alignItems: 'flex-start',
      },
    },
    Step: {
      // Styles for StyledStep
      baseStyle: {
        height: '40px',
        flex: '1 0 0',
      },
    },
    Text: {
      baseStyle: {
        color: Palette.Grey700,
        fontSize: 'sm',
        lineHeight: '20px',
        letterSpacing: '0.048px',
      },
    },
    Card: {
      baseStyle: {
        borderRadius: '4px',
        border: '1px solid',
        borderColor: Palette.Grey400,
        background: Palette.Grey300,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flex: '1 0 0',
        maxWidth: '60vh',
      },
    },
    Select: {
      baseStyle: {
        field: {
          borderRadius: '4px',
          border: '1px solid',
          borderColor: Palette.Grey400,
          color: Palette.Grey700,
          _focus: {
            boxShadow: '0 0 0 2px #5A67D8', // Outline color
            borderColor: Palette.Indigo500, // Border color on focus
          },
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          borderRadius: '4px',
          border: '1px solid',
          borderColor: Palette.Grey400,
          background: Palette.Grey300,
          color: Palette.Grey700,
          _focus: {
            boxShadow: '0 0 0 2px #5A67D8', // Outline color
            borderColor: Palette.Indigo500, // Adjust if you want to change the border color on focus
          },
        },
      },
    },
  },
});

export default theme;
