import React from 'react';
import { Tr, Td, Select, Text } from '@chakra-ui/react';

function ResourceItem({
  resource,
  machines,
  machineGroups,
  currentMapping = {},
  handleResourceMappingChange,
}) {
  const handleMachineSelect = (resourceId, machineRef) => {
    handleResourceMappingChange(resourceId, {
      machineRef: machineRef === '' ? null : parseInt(machineRef),
      machineGroupId: null,
    });
  };

  const handleMachineGroupSelect = (resourceId, machineGroupId) => {
    handleResourceMappingChange(resourceId, {
      machineGroupId: machineGroupId === '' ? null : machineGroupId,
      machineRef: null,
    });
  };

  const currentMachineRef = currentMapping.machineRef;
  const currentMachineGroupId = currentMapping.machineGroupId;

  return (
    <Tr>
      <Td>
        <Text fontSize="md">{resource.name || resource.resourceId}</Text>
      </Td>
      <Td
        title={resource.description}
        style={{
          maxWidth: '150px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {resource.description}
      </Td>
      <Td>
        <Select
          placeholder="Select machine"
          value={currentMachineRef || ''}
          onChange={(e) =>
            handleMachineSelect(resource.resourceId, e.target.value)
          }
          disabled={!!currentMachineGroupId}
        >
          {machines.map((machine) => (
            <option key={machine.machineRef} value={machine.machineRef}>
              {machine.name}
            </option>
          ))}
        </Select>
      </Td>
      <Td>
        <Select
          placeholder="Select machine group"
          value={currentMachineGroupId || ''}
          onChange={(e) =>
            handleMachineGroupSelect(resource.resourceId, e.target.value)
          }
          disabled={!!currentMachineRef}
        >
          {machineGroups.map((group) => (
            <option key={group.id} value={group.id}>
              {group.name}
            </option>
          ))}
        </Select>
      </Td>
    </Tr>
  );
}

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.resource?.resourceId === nextProps.resource?.resourceId &&
    prevProps.currentMapping?.machineRef ===
      nextProps.currentMapping?.machineRef &&
    prevProps.currentMapping?.machineGroupId ===
      nextProps.currentMapping?.machineGroupId
  );
};

export default React.memo(ResourceItem, areEqual);
