import React from 'react';
import { Tr, Td, Text, Select } from '@chakra-ui/react';

const PersonItem = ({
  person,
  mmOperators,
  onOperatorChange,
  selectedOperatorId,
  selectedOperators,
}) => {
  const handleSelectChange = (event) => {
    const operatorId = parseInt(event.target.value);
    onOperatorChange(person.personId, operatorId);
  };

  const isOperatorAssigned = (operatorId) => {
    // Check if the operator is already assigned to a different person
    return Object.values(selectedOperators).includes(operatorId);
  };

  return (
    <Tr>
      <Td>
        <Text fontSize="md">
          {person.firstName} {person.lastName}
        </Text>
      </Td>
      <Td>
        <Select
          placeholder="Select Operator"
          maxW="300px"
          value={selectedOperatorId}
          onChange={handleSelectChange}
        >
          {mmOperators.map((operator) => (
            <option
              key={operator.id}
              value={operator.id}
              disabled={isOperatorAssigned(operator.id)}
            >
              {operator.name}
            </option>
          ))}
        </Select>
      </Td>
    </Tr>
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.person.personId === nextProps.person.personId &&
    prevProps.selectedOperatorId === nextProps.selectedOperatorId &&
    prevProps.selectedOperators === nextProps.selectedOperators &&
    prevProps.mmOperators === nextProps.mmOperators
  );
};

export default React.memo(PersonItem, areEqual);
