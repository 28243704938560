import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    fontWeight: 400,
    borderRadius: '0.125rem',
    justifyContent: 'center',
  },
});

export const tagTheme = defineMultiStyleConfig({ baseStyle });
