const Palette = {
  Black: '#000000',
  White: '#FFFFFF',
  BrandGreen: '#15A500',
  DashboardBlue: '#428BCA',
  CheckboxGreen: '#16A500',
  Grey50: '#FCFCFC',
  Grey75: '#F9FBFD',
  Grey100: '#F7FAFC',
  Grey150: '#F2F6FA',
  Grey200: '#EDF2F7',
  Grey300: '#E2E8F0',
  Grey400: '#CBD5E0',
  Grey500: '#A0AEC0',
  Grey600: '#718096',
  Grey700: '#4A5568',
  Grey800: '#2D3748',
  Grey900: '#1A202C',
  Red100: '#FFF5F5',
  Red200: '#FED7D7',
  Red300: '#FEB2B2',
  Red400: '#FC8181',
  Red500: '#F56565',
  Red600: '#E53E3E',
  Red700: '#C53030',
  Red800: '#9B2C2C',
  Red900: '#742A2A',
  Orange100: '#FFFAF0',
  Orange200: '#FEEBC8',
  Orange300: '#FBD38D',
  Orange400: '#F6AD55',
  Orange500: '#ED8936',
  Orange600: '#DD6B20',
  Orange700: '#C05621',
  Orange800: '#9C4221',
  Orange900: '#7B341E',
  Yellow100: '#FFFFF0',
  Yellow200: '#FEFCBF',
  Yellow300: '#FAF089',
  Yellow400: '#F6E05E',
  Yellow500: '#ECC94B',
  Yellow600: '#D69E2E',
  Yellow700: '#B7791F',
  Yellow800: '#975A16',
  Yellow900: '#744210',
  Green50: '#F7FFF9',
  Green100: '#F0FFF4',
  Green200: '#C6F6D5',
  Green300: '#9AE6B4',
  Green400: '#68D391',
  Green500: '#48BB78',
  Green600: '#38A169',
  Green700: '#2F855A',
  Green800: '#276749',
  Green900: '#22543D',
  Teal100: '#E6FFFA',
  Teal200: '#B2F5EA',
  Teal300: '#81E6D9',
  Teal400: '#4FD1C5',
  Teal500: '#38B2AC',
  Teal600: '#319795',
  Teal700: '#2C7A7B',
  Teal800: '#285E61',
  Teal900: '#234E52',
  Blue100: '#EBF8FF',
  Blue200: '#BEE3F8',
  Blue300: '#90CDF4',
  Blue400: '#63B3ED',
  Blue500: '#4299E1',
  Blue600: '#3182CE',
  Blue700: '#2B6CB0',
  Blue800: '#2C5282',
  Blue900: '#2A4365',
  Indigo50: '#F5FAFF',
  Indigo100: '#EBF4FF',
  Indigo200: '#C3DAFE',
  Indigo300: '#A3BFFA',
  Indigo400: '#7F9CF5',
  Indigo500: '#667EEA',
  Indigo600: '#5A67D8',
  Indigo700: '#4C51BF',
  Indigo800: '#434190',
  Indigo900: '#3C366B',
  Purple50: '#FCFAFF',
  Purple100: '#FAF5FF',
  Purple200: '#E9D8FD',
  Purple300: '#D6BCFA',
  Purple400: '#B794F4',
  Purple500: '#9F7AEA',
  Purple600: '#805AD5',
  Purple700: '#6B46C1',
  Purple800: '#553C9A',
  Purple900: '#44337A',
  Pink100: '#FFF5F7',
  Pink200: '#FED7E2',
  Pink300: '#FBB6CE',
  Pink400: '#F687B3',
  Pink500: '#ED64A6',
  Pink600: '#D53F8C',
  Pink700: '#B83280',
  Pink800: '#97266D',
  Pink900: '#702459',
};

export default Palette;
