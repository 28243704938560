import React, { useState, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, Input, Table, Thead, Tbody, Th, Tr } from '@chakra-ui/react';
import PersonItem from './PersonItem';

const erpPersonsQuery = gql`
  query erpPersonsQuery {
    erpPersons {
      firstName
      isActive
      lastName
      personId
    }
  }
`;

const People = ({ selectedOperators, onOperatorChange, operators }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { data: erpPersons, loading: erpPersonsLoading } =
    useQuery(erpPersonsQuery);

  const filteredPersons = useMemo(() => {
    if (!erpPersons?.erpPersons) return [];
    return erpPersons.erpPersons.filter(
      (person) =>
        (person.firstName ? person.firstName.toLowerCase() : '').includes(
          searchTerm.toLowerCase()
        ) ||
        (person.lastName ? person.lastName.toLowerCase() : '').includes(
          searchTerm.toLowerCase()
        )
    );
  }, [erpPersons?.erpPersons, searchTerm]);

  if (erpPersonsLoading) return <p>Loading...</p>;

  return (
    <Box width="100%">
      <Input
        placeholder="Filter by Name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        mb={4}
      />
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ERP Name</Th>
            <Th>MachineMetrics Operator</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredPersons.map((person) => (
            <PersonItem
              key={person.personId}
              person={person}
              mmOperators={operators}
              onOperatorChange={onOperatorChange}
              selectedOperatorId={selectedOperators[person.personId] || ''}
              selectedOperators={selectedOperators}
            />
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default People;
