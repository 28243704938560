import React from 'react';
import { gql, useQuery } from '@apollo/client';
import AppContext from './AppContext';

function AppProvider({ children }) {
  const { data } = useQuery(gql`
    query CompanyData {
      companies {
        name
        location {
          locationRef
        }
        machines {
          machineRef
          name
        }
      }
    }
  `);

  return (
    <AppContext.Provider
      value={{
        // Add your state here
        company: data?.companies[0],
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
