import { useState, useEffect } from 'react';
import { useMMAuth } from '@machinemetrics/mm-react-tools';

const useFetchOperators = () => {
  const { request, urls } = useMMAuth();
  const [operators, setOperators] = useState([]);
  const [selectedOperators, setSelectedOperators] = useState({});

  useEffect(() => {
    const fetchOperators = async () => {
      try {
        const response = await request(`${urls.apiUrl}/operator`, {
          method: 'GET',
        });
        if (!response.error && Array.isArray(response)) {
          setOperators(response);

          const newOperatorAssignments = response.reduce((acc, operator) => {
            if (operator.erpId) {
              acc[operator.erpId] = operator.id;
            }
            return acc;
          }, {});

          setSelectedOperators(newOperatorAssignments);
        }
      } catch (error) {
        console.error('Error fetching operators:', error);
      }
    };

    fetchOperators();
  }, [request, urls.apiUrl]);

  return { operators, selectedOperators, setSelectedOperators };
};

export default useFetchOperators;
