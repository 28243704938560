import { css } from 'styled-components';

const BASE_EM = 16;

/* 
  The way to think of mediaMin and mediaMax is:

   mediaMin.tablet means "Tablet size and up"

   mediaMax.tablet means "Tablet size and down"
*/

export const mediaBreakpoints = {
  hd: { min: 1600, max: Infinity },
  desktop: { min: 992, max: 1599 },
  tablet: { min: 768, max: 991 },
  phone: { min: 468, max: 767 },
  phonePortrait: { min: 0, max: 468 },
  mini: { min: 0, max: 350 },
};

export const mediaMin = Object.keys(mediaBreakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${mediaBreakpoints[label].min / BASE_EM}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export const mediaMax = Object.keys(mediaBreakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${mediaBreakpoints[label].max / BASE_EM}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

// Manages z-index layering.
// Initially placing within range of 1-100.

export const zLayer = {
  raised: 10,
  tooltip: 20,
  menuScreen: 25,
  menu: 30,
  headerScreen: 50,
  header: 60,
  screen: 65,
  headerMenu: 70,
  modalScreen: 75,
  modal: 80,
  modalMenu: 85,
  fullscreen: 90,
  portal: 100,
};
